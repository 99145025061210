module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WM5R2RP","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["nl","fr"],"defaultLanguage":"nl","siteUrl":"https://trooper.be","generateDefaultLanguagePage":true,"i18nextOptions":{"defaultNS":"common","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang/associations-trooper","getLanguageFromPath":true,"languages":["fr"]},{"matchPath":"/:lang/trooperverenigingen","getLanguageFromPath":true,"languages":["nl"]},{"matchPath":"/:lang/trooperverenigingen/:pageName","getLanguageFromPath":true,"languages":["nl"]},{"matchPath":"/:lang/associations-trooper/:pageName","getLanguageFromPath":true,"languages":["fr"]},{"matchPath":"/:lang/gemiste-aakoop","getLanguageFromPath":true,"languages":["nl"]},{"matchPath":"/:lang/achat-manque","getLanguageFromPath":true,"languages":["fr"]},{"matchPath":"/:lang/index","getLanguageFromPath":true,"excludeLanguages":["fr","nl"]},{"matchPath":"/:lang/julesdestrooper","getLanguageFromPath":true,"languages":["nl"]},{"matchPath":"/:lang/julesdestrooper","getLanguageFromPath":true,"languages":["fr"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
